<template>
  <div class="header-wrap">
		<div class="i-topWrap">
			<div class="i-top clearfix wrap">
				<div class="i-top-left">
					<span>中文<i class="i-top-left-i"><img src="../assets/images/icon-sj-b_03.png"/></i></span>|<span @click="mobileBtn"><img src="../assets/images/icon-phone_03.png" />手机版</span>
					<div class="mobilePop"  v-show="mobilePop">
						<img src="../assets/images/erweima.png" alt="" />
						<p>扫一扫</p>
					</div><!-- mobilePop end -->
				</div>
		
				<div class="i-top-right">
					<div class="i-top-rSelect">
						<el-select v-model="value" placeholder="请选择">
						    <el-option
						      v-for="item in options"
						      :key="item.value"
						      :label="item.label"
						      :value="item.value">
						    </el-option>
						  </el-select>
					</div>
					<input type="text" placeholder="请输入关键词" class="i-top-rInput" v-model="keywords"/>
					<button type="button" class="i-top-rBtn" @click="searchBtn">搜索</button>
				</div>
		
				<h1 class="i-logo">
					<img src="../assets/images/i-logo.png" alt="" />
				</h1>
		
			</div>
		
		
		</div>
		<!-- i-topWrap end -->
		
		<div class="i-navWrap">
			<div class="i-navbar wrap clearfix">
				<div class="i-nav fl">
					<router-link to="/index" class="i-nav-item" active-class="activeNav">
						<span class="i-nav-item-text">首页</span>
					</router-link>
					<router-link to="/category" class="i-nav-item" active-class="activeNav">
						<span class="i-nav-item-text">品牌分类</span>
					</router-link>
					<router-link to="/article" class="i-nav-item" active-class="activeNav">
						<span class="i-nav-item-text">品牌百科</span>
					</router-link>
					<router-link to="" class="i-nav-item">
						<span class="i-nav-item-text">生活排行</span>
					</router-link>
					<router-link to="" class="i-nav-item">
						<span class="i-nav-item-text">品牌服务<img src="../assets/images/icon-nav-hot_03.png" alt="" class="i-nav-hot"/></span>
					</router-link>
				</div>
				
				<div class="i-navbar-right">
					<div class="i-navbar-rUser">
						<a href="#"><i class="i-nav-icon"><img src="../assets/images/icon-user_03.png" alt="" /></i>登录</a>
						<a href="#">注册</a>
					</div>
						
					<div class="i-navbar-fabu">
						<a href="#"><i class="i-nav-icon"><img src="../assets/images/icon-fabu_03.png" alt="" /></i>发布</a>
					</div>
					
				</div><!-- i-navbar-right end -->
			</div>
		</div>
		<!-- i-navWrap end -->
  </div>
</template>

<script>
	import {ppSearch,hySearch} from "../api/search.js";
	export default {
		data() {
			return {
				keywords:"",//搜索关键词
				mobilePop:false,//手机版弹层
				options: [{
				  value: '行业',
				  label: '行业'
				}, {
				  value: '品牌',
				  label: '品牌'
				}],
				value: '行业'
				
			}
		},
		props: {
		    placeholder: String,
			
		},
		computed: {
      
			active() {
				console.log(this.$route)
				return this.$route.matched[0].path
			}
		},
  
		methods:{
			handleMenuSelect(val){
				this.$router.push({path: val})
			},

			//顶部二维码弹出层
			mobileBtn(){
				this.mobilePop = !this.mobilePop
				setTimeout(() => {
					this.mobilePop = false
				}, 3000);
			},
			
			// 搜索
			searchBtn(){
				this.$emit('onSearch')
				
				console.log(this.keywords,this.value,'组件执行')
				var keywords = this.keywords
				var type = this.value
				
				this.$router.push({
					path: '/search',
					query: { keywords: keywords, type: type}
				});
				
				
			},

		
		}
	}
</script>

<style scoped>
 
 .i-topWrap{
 	background: #fff;
 }
 
 .i-top{
 	min-height: 80px;
 	padding: 32px 0;
 }
 
 .i-top-left{
 	float: left;
 	height: 24px;
 	margin-top: 28px;
 	line-height: 24px;
 	color: #dddde5;
 	font-size: 16px;
 	position: relative;
 }
 
 .i-top-left span{
 	display: inline-block;
 	margin: 0 20px;
 	cursor: pointer;
 	color: #000;
 }
 
 .i-top-left span>img{
 	width: 24px;
 	height: 24px;
 	vertical-align: middle;
 	margin-right: 6px;
 }
 
 .i-top-left-i{
 	display: inline-block;
 	width: 6px;
 	height: 4px;
 	margin-left: 5px;
 }
 
 .i-top-left-i img{
 	width: 100%;
 	height: 100%;
 	vertical-align: middle;
 }
 
 .i-logo{
 	height: 80px;
 	margin: 0 25%;
 	text-align: center;
 }
 
 .i-logo img{
 	height: 100%;
 }
 
 
 .mobilePop{
 	text-align: center;
 	position: absolute;
 	width: 200px;
 	height: 240px;
 	background: #fff;
 	border: 1px solid #ececec;
 	z-index: 9999;
 	overflow: hidden;
 	box-sizing: border-box;
 	padding: 20px;
 	left: 0;
 	top: 120%;
 }
 
 .mobilePop>img{
 	width: 100%;
 	vertical-align: top;
 }
 
 .mobilePop p{
 	color: #000;
 	font-size: 16px;
 	line-height: 30px;
 	margin-top: 10px;
 }
 
 .i-top-right{
 	float: right;
 	height: 42px;
 	width: 350px;
 	border-radius: 30px;
 	-webkit-border-radius: 30px;
 	overflow: hidden;
 	margin-top: 19px;
	
 }
 
 .i-top-rSelect{
 	width: 80px;
 	float: left;
	border-top-left-radius: 30px;
	border-bottom-left-radius: 30px;
	height: 40px;
	border: 1px solid #e5e5e9;
 }
 
  .i-top-rSelect /deep/ .el-input__inner{
/* 	border-radius: 0; */
	border: 0;
	border-top-left-radius: 30px;
	border-bottom-left-radius: 30px;
 }
 
 .i-top-rInput{
 	width: 155px;
 	padding: 0 20px ;
 	height: 40px;
 	border: 1px solid #e5e5e9;
 	/* background: #fff url("../assets/images/icon-search_03.png") 20px center no-repeat; */
 	float: left;
 	border-left: 0;
	border-right: 0;
 	font-size: 16px;
 }
 
 .i-top-rBtn{
 	width: 72px;
 	height: 42px;
 	text-align: center;
 	font-size: 16px;
	background: #da0505 !important;
 	line-height: 42px;
 	color: #fff;
 	float: right;
 }
 
 .i-navWrap{
 	background: #da0505;
 	height: 50px;
 }
 
 
 .i-navbar,
 .i-nav{
 	height: 50px;
 }
 
 
 .i-nav-icon{
 	width:32px;
 	height:32px;
 	display: inline-block;
 	margin-right: 5px;
 	position: relative;
 	top: -2px;
 }
 
 
 .i-nav-icon img{
 	width: 100%;
 	height: 100%;
 	vertical-align: middle;
 }
 
 .i-nav-item{
 	padding: 0 50px;
	/* width: 130px; */
 	height: 50px;
 	line-height: 50px;
 	float: left;
 	position: relative;
 	display: block;
 	margin-right: 2px;
	text-align: center;
 }
 
 .i-nav-item-text{
 	display: inline-block;
 	color: #fff;
 	font-size: 18px;
 	position: relative;
 	height: 50px;
 }
 
 .i-nav-item:hover,
 .i-nav-item.activeNav{
 	background: #b81313 !important;
 }
 
 .i-nav-hot{
 	width: 32px;
 	height: 20px;
 	position: absolute;
 	top: -5px;
 	right: -30px;
 }
 
 .i-navbar-right{
 	float: right;
 	height: 50px;
 }
 
 
 .i-navbar-right a{
 	color: #fff;
 	font-size: 16px;
 }
 
 .i-navbar-rUser{
 	float: left;
 }
 
 .i-navbar-rUser a{
 	display: inline-block;
 	height: 50px;
 	line-height: 50px;
 	padding: 0 15px;
 }
 
 
 .i-navbar-fabu{
 	float: right;
 }
 
 .i-navbar-fabu a{
 	display: block;
 	height: 50px;
 	width: 130px;
 	text-align: center;
 	/* background: #b81313; */
 	line-height: 50px;
 }

	.i-navbar-fabu a:hover{
		background: #b81313;
	}



</style>
